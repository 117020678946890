import {Component, EventEmitter, Input, Output, booleanAttribute, ViewChild} from "@angular/core";
import { ApiService } from "../services/api.service";
import { LangService } from "../services/lang.service";
import { ValidatorService } from "../services/validator.service";
import { ModalService } from "../services/modal.service";
import { StoreService } from "../services/store.service";
import { GenTableService } from "../services/gen-table-service.service";
import { PermsService } from "../services/perms.service";
import { ActivatedRoute } from "@angular/router";
import { Contact, Contactway, Payor } from "../definitions/types";
import {GenAutocompleteComponent} from "@app/gen-inputs/gen-autocomplete/gen-autocomplete.component";

@Component({ templateUrl: './person-update-commons.component.html' })
export class PersonUpdateCommons{
  @Output() addInvoiceEv = new EventEmitter();	//notify parent of a request to add invoice
	@Input({transform:booleanAttribute}) isModalMode: boolean=false;
  @Output() closeModal = new EventEmitter();
  @Input() payor: Payor;	//the payor object to update
  @Input() contact: Contact;	//the contact object to update
  @Output() openReport = new EventEmitter();	//notify parent of a request to add invoice

  isSaving:boolean=false;
  contactways:Contactway[]=[];
  budget_for_payors:string="no";
	errors: any = {};	//will hold errors for the fields in the form
	isFormSubmittable: boolean = false;	//can submit (controls disabled in the submit button)
	validationFields: any = {	//field validations, control displaying field errors and ability to submit the form
		// name:{'not_empty':null},

	};
  tableName:string="";


  constructor(protected apiService: ApiService, public lang: LangService, public validator: ValidatorService, public modalService: ModalService, protected store: StoreService,protected genTableService: GenTableService,public permsService: PermsService,protected route: ActivatedRoute) {
    this.apiService.sendApiGetPerms();

    /////////////
    // const keys=Object.keys(this.genTableService.tableNamesToInitFuncs);
    // keys.forEach(key=>{
    //   const obj:any={};
    //   this.genTableService.tableNamesToInitFuncs[key](obj);
    //   const fields=obj.collectionFields.filter(f=>f.type==="input" && f.isNumberField);
    //   if(fields.length){
    //     console.log(key,fields.map(f=>f.fieldName));
    //   }
    // })
    //
    ///////////////
  }

  loadValidationFields(){
    this.validationFields={};
    const tmpTable:any={};
    this.genTableService.tableNamesToInitFuncs[this.tableName](tmpTable);
    if(tmpTable?.collectionFields){
      tmpTable.collectionFields.forEach(field=>{
        if(field?.validationRules){
          this.validationFields[field.fieldName]=field.validationRules;
        }
      })
    }

  }
  getContactwayTopId(){
    return !this.contactways.length ? 0 : this.contactways[this.contactways.length-1].id;
  }
  addContactway(avoidValidation:boolean=false){
    const nextId=this.getContactwayTopId()+1;
    const obj=new Contactway();
    obj.id=nextId;
    this.contactways=[...this.contactways,obj];
    if(!avoidValidation){
      this.validateEntireForm();
    }
  }
  removeContactway(id:number){
    this.contactways=this.contactways.filter(it=>it.id!=id);
    this.validateEntireForm();
  }
  validateEntireForm() {	//validate all fields and detrmine if can be submitted (or has errors)
		this.isFormSubmittable = this.validator.validateEntireForm(this.getPerson(), this.errors, this.validationFields);

    this.contactways.forEach(c=>{
      c.errors={};
      const isSubmittable=this.validator.validateEntireForm(c, c.errors, {contactway:{not_empty:true}});
      if(!isSubmittable){
        this.isFormSubmittable=false;
      }
    })
	}
  validateField(fieldName:string){
    if(!this.getPerson().id){return this.validateEntireForm();}
    if(this.validationFields[fieldName]){
      this.validator.validateField(this.getPerson(), this.errors, fieldName,this.validationFields[fieldName]);
    }
    this.isFormSubmittable = this.validator.hasNoErrors(this.errors);
  }
  async onChange(fieldName:string){
    if(!this.getPerson().id){return;}
    if(this.errors[fieldName]){return;}
    await this.apiService.sendApiGetPerms();
    if(!this.apiService.apiCallPerms || !this.apiService.apiCallPerms[this.tableName]?.saveField){return}
    const res:any=await this.apiService.save_gen_field(this.tableName, this.getPerson().id, fieldName, this.getPerson()[fieldName]);
    this.store.updGenItemRowField(this.tableName,this.getPerson().id,fieldName,this.getPerson()[fieldName]);
    let message = res?.success ? this.lang.getVal("updated_successfully") : this.lang.getVal("save_failed");	//set message by saving success
    this.modalService.openToast(message);	//toast the message to user

  }
  async save() {	//save the contact
    if(!this.isFormSubmittable){return;}
    await this.apiService.sendApiGetPerms();
    if(!this.apiService.apiCallPerms || !this.apiService.apiCallPerms[this.tableName]?.saveNewRow){return}
    this.isSaving=true;
    const res:any=await this.apiService.save_gen_item(this.tableName, {...this.getPerson(),contactways:this.contactways});
    this.isSaving=false;
    if (res.id) {
      this.store.addGenItemRow(this.tableName, res);
      this.closeModal.emit();
      this.store.refreshTableSubject.next(this.tableName);
    }
    const message = this.lang.getVal( res?.id ? "saved_successfully" : ( res?.mes || "save_failed" ) ) ;
    this.modalService.openToast(message);
	}

  getPerson(){
    return this[this.tableName=="contacts" ? "contact" : "payor"];
  }
  openPayorprices(){};

}
